<template>
    <div >
        <StaffChargeMessageItem v-for="(item,index) of extraData" :key="item.id" :extraDataItem='item' :index="index"></StaffChargeMessageItem>
    </div>
</template>
<script>
import StaffChargeMessageItem from 'components/staffMessage/StaffChargeMessageItem.vue'
export default{
    name:'StaffChargeMessage',
    components:{
        StaffChargeMessageItem
    },
    props:{
        extraData:{
            type:Array
        }
    }
}
</script>

