<template>
    <div>
        <div v-if="isShow">
            <NavBar>
                <img src="~assets/image/home/left.png" alt="" slot="left" class="left">
                <span slot="center">详细信息</span>
            </NavBar>
            <div class="StaffList">
                <StaffMessage :extra="extra"></StaffMessage>
                <div class="lineDiv"></div>
                <StaffChargeMessage :extraData="extraData" />
                <StaffSuggest></StaffSuggest>
            </div>
        </div>
          
        
        <router-view />
    </div>
</template>
<script>
    import NavBar from 'components/navBar/NavBar.vue'
    import StaffMessage from 'components/staffMessage/StaffMessage.vue'
    import StaffChargeMessage from 'components/staffMessage/StaffChargeMessage.vue'
    import {getTaskDetail} from '../../network/normal'
    import StaffSuggest from 'components/staffSuggest/StaffSuggest.vue'

    export default{
        name:'CheckMessage',
        components:{
            NavBar,
            StaffMessage,
            StaffChargeMessage,
            StaffSuggest
        },
        created(){
           let id = localStorage.getItem('taskId');
            this.$toast.loading({
                message: '努力加载中...',
                forbidClick: false,
                duration:0
            });
            getTaskDetail(id).then((res)=>{
                this.$toast.clear();
                if(res.success){
                    this.extra = res.extra
                    this.extraData = res.data
                }
            });
        },
        beforeRouteUpdate (to, from, next) {
            console.log(to)
            if(from.name == 'CheckDetail'){
                 let id = localStorage.getItem('taskId');
                getTaskDetail(id).then((res)=>{
                  
                    if(res.success){
                        this.extra = res.extra
                        this.extraData = res.data
                    }
                });
            }
            next()
        },
        data(){
            return {
                isShow:true,
                extra:{},
                extraData:[]
            }
        },
         watch:{
            $route(newVal){
                if(newVal.path.indexOf('checkDetail') != -1){
                    this.isShow = false;
                }else{
                    this.isShow = true;
                }
            }
        },
        mounted() {
            if(this.$route.path.indexOf('checkDetail') != -1){
                this.isShow = false;
            }
        }
    }
</script>
<style scoped>
 .left{
        margin-left: 24px;
        width: 23px;
        height: 41px; 
    }
    .StaffList{
        margin-top: 160px;
    }
    .lineDiv{
        width: 100%;
        height: 20px;
        background: #EEEEEE;
    }

    
    
</style>
