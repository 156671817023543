<template>
    <div class="staffMessage">
        <p class="name">{{extra.username}}<span class="score">(点评总分:<span class="scoreGrade">{{extra.finalScore ?extra.finalScore:'暂未评分'}}</span><span>)</span></span></p>
        <div class="messageDiv">
            <p class="leftTitle">员工编号</p>
            <p class="rightText">{{extra.account}}</p>
        </div>
        <div class="messageDiv">
            <p class="leftTitle">职称</p>
            <p class="rightText">大堂经理</p>
        </div>
        <div class="messageDiv">
            <p class="leftTitle">支行</p>
            <p class="rightText">{{extra.orgName}}</p>
        </div>
        <div class="messageDiv">
            <p class="leftTitle">到职日</p>
            <p class="rightText">2017.8.20</p>
        </div>
        <div class="messageDiv">
            <p class="leftTitle">考核日期</p>
            <p class="rightText">{{extra.startTime}} - {{extra.endTime}}</p>
        </div>
    </div>
</template>
<script>
    export default{
        name:'StaffMessage',
        props:{
            extra:{
                type:Object
            }
        }
    }
</script>
<style scoped>
    .name{
        margin: 0px 0px 52px 23px;
        font-size: 60px;
        font-family: PingFang;
        font-weight: 500;
        color: #333333;
    }
    .score{
        margin-left: 26px;
        font-size: 30px;
        font-family: PingFang;
        font-weight: 500;
        color: #999999;
    }
    .scoreGrade{
        color: #333333;
    }
    .messageDiv{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 24px;
        padding-right: 24px;
        border-bottom: solid #E3E3E4 1px;
    }
    .leftTitle{
        font-size: 30px;
        font-family: PingFang;
        font-weight: 500;
        color: #333333;
        line-height: 88px;
    }
    .rightText{
        color: #999999;
    }
</style>
