<template>
    <div class="staffCharge">
        <div class="chargeMessage" @click="toChargeMessage()">
            <div class="left">
                <img :src="forMyImg" alt="">
                <span class="leftOneSpan">{{extraDataItem.bigTypeName}}</span>
                <span class="leftSpan">(评分:{{extraDataItem.finalScore?extraDataItem.finalScore:'暂无评分'}})</span>
                <span :class="extraDataItem.refuseFlag == 0 ?'stateEndText':'stateText'">{{refuseFlag}}</span>
            </div>
            <div class="right">
                <span v-if="extraDataItem.id">{{extraDataItem.weight}}%</span>
                <img src="~assets/image/home/right.png" alt="">
            </div>
        </div>    
    </div>
</template>
<script>
export default{
    name:'StaffChargeMessageItem',
    data(){
        return {
        }
    },
    computed:{
        forMyImg(){
            
            let path = require('../../assets/image/staffMessage/'+ this.index +'.png');
           if(this.index > 6){path = require('../../assets/image/staffMessage/'+ 0 +'.png');}
            return  path;
        },
        refuseFlag(){
            let text = '';
            if(this.extraDataItem.refuseFlag == null || this.extraDataItem.refuseFlag ==1){
                text = '待提交'

              
            }else if(this.extraDataItem.refuseFlag == 0){
                text =  '已提交'
            }
            return text;
            
        }
     
    },
    props:{
        extraDataItem:{
            type:Object
        },
        index:{
            Number
        }
    },
    methods:{
        toChargeMessage(){
            let id = this.extraDataItem.id
            this.$router.push({
                        name:'CheckDetail',
                     params:{
                         id:id,
                         maxScore:this.extraDataItem.maxScore,
                         weight:this.extraDataItem.weight,
                         title:this.extraDataItem.bigTypeName
                     }
                })
        }
    }
}
</script>
<style scoped>
.chargeMessage{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 23px;
    padding-right: 24px;
    border-bottom:  solid 1px #E3E3E4;
}
.left img{
    margin-right: 31px;
    width: 40px;
    vertical-align: middle;
}
.leftOneSpan{
    font-size: 30px;
    font-family: PingFang;
    font-weight: 500;
    color: #333333;
    line-height: 88px;
}
.right img{
    width: 14px;
}
.right span{
    font-size: 30px;
    font-family: PingFang;
    font-weight: 500;
    color: #999999;
    margin-right: 16px;
}
.leftSpan{
     font-size: 24px;
    font-family: PingFang;
   
    color: #999999;
    margin-left: 7px;
}
.stateText{
    margin-left: 10px;
    line-height: 28px;
    background: #FAEAEA;
    border-radius: 14px;
    font-size: 20px;
    font-weight: 400;
    color: #D72E2E;
    padding: 4px 14px;
}
.stateEndText{
    margin-left: 10px;
    line-height: 28px;
    background: #E2F5ED;
    border-radius: 14px;
    font-size: 20px;
    font-weight: 400;
    color: #0C8117;
    padding: 4px 14px;
}
</style>
