<template>
    
            <div class="staffCharge" @click="toGoSuggestMes">
                    <div class="chargeMessage">
                        <div class="leftDiv">
                            <img src="~assets/image/staffMessage/6.png" alt="">
                            <span>补充说明或建议</span>
                        </div>
                        <div class="rightDiv">
                            <img src="~assets/image/home/right.png" alt="">
                        </div>
                    </div>    
            </div>


     
</template>
<script>


export default {
    name:'StaffSuggest',
    methods:{
        toGoSuggestMes(){
            
            this.$router.push({
                name:'SuggestMessage'
            })
        }
    }
}
</script>

<style scoped>
.chargeMessage{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 23px;
    padding-right: 24px;
    border-bottom:  solid 1px #E3E3E4;
}
.leftDiv img{
    margin-right: 31px;
    width: 40px;
    vertical-align: middle;
}
.leftDiv span{
    font-size: 30px;
    font-family: PingFang;
    font-weight: 500;
    color: #333333;
    line-height: 88px;
}
.rightDiv img{
    width: 14px;
}
.rightDiv span{
    font-size: 30px;
    font-family: PingFang;
    font-weight: 500;
    color: #999999;
    margin-right: 16px;
}
</style>


    

